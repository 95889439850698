import { configureStore } from '@reduxjs/toolkit'
import eventsSlice from './slices/eventsSlice'
import rolesReducer from './slices/rolesSlice'
import usersReducer from './slices/usersSlice'

export default configureStore({
    reducer: {
        roles: rolesReducer,
        users: usersReducer,
        events: eventsSlice
    }
})