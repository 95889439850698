import { Dialog, DialogTitle } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import UserList from '../routes/UserList'

export default function RoleAssignmentEditDialog(props) {
    const roles = useSelector(state => state.roles.roles)

    if (!props.assignment) {
        return <></>
    }

    const role = roles.find(role => role.url === props.assignment.role)

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>
                Mitarbeiter für {role.name} wählen
            </DialogTitle>
            <UserList onSelect={props.onSelect} />
        </Dialog>
    )
}

