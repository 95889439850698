import { createSlice } from "@reduxjs/toolkit"
import api from '../api'

export const usersSlice = createSlice({
    name: 'users',
    initialState: {
        users: [],
        whoami: null
    },
    reducers: {
        setUsers: (state, action) => {
            state.users = action.payload
        },
        setWhoAmI: (state, action) => {
            state.whoami = action.payload
        }
    }
})

export async function fetchUsers(dispatch, getState) {
    api.fetchUsers()
    .then(users => {
        dispatch({ type: 'users/setUsers', payload: users })
    })
}

export async function fetchWhoAmI(dispatch, getState) {
    api.fetchWhoAmI()
    .then(whoami => {
        dispatch({ type: 'users/setWhoAmI', payload: whoami})
    })
}

export const { setRoles } = usersSlice.actions
export default usersSlice.reducer
