import { useState } from 'react'
import { TextField, Typography, Button, Grid } from '@mui/material'
import api from '../api'
import { useSelector } from 'react-redux'
import { EventCommentCard } from './EventCommentCard'
import { AttachEmailOutlined } from '@mui/icons-material'
import fileSize from 'filesize'


export const EventComments = ({ event, onNewComment }) => {
    const [new_comment, setNewComment] = useState("")
    const [sending, setSending] = useState(false)
    const [attachment, setAttachment] = useState(null)
    const whoami = useSelector(s => s.users.whoami)

    const postComment = () => {
        setSending(true)
        let send_comment = {
            event: event.url,
            created_by: whoami.url,
            content: new_comment,
        }
        if (attachment) {
            send_comment.attachment = attachment[0]
        }
        api.createEventComment(send_comment)
        .then(comment => {
            setNewComment("")
            setSending(false)
            onNewComment()
        })
    }

    const deleteComment = (comment) => {
        if (window.confirm("Soll der Kommentar wirklich gelöscht werden?")) {
            api.deleteEventComment(comment)
            .then(() => {
                onNewComment()
            })
        }
    }

    const fileInfo = attachment && attachment.length > 0 ? attachment[0].name + " (" + fileSize(attachment[0].size) + ")" : "Keine Datei angehängt"

    return (
        <>
            <Typography variant="h5" sx={{ mb: "1rem", mt: "1rem" }}>
                Kommentare
            </Typography>
            <TextField
                fullWidth
                label="Neuer Kommentar"
                variant="outlined"
                value={new_comment}
                onChange={e => setNewComment(e.target.value)}
                multiline
                minRows={3}
                margin="dense"
            />
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mt: '1em' }}
            >
                <Grid item>
                    <Typography variant="body2">
                        {fileInfo}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        component="label"
                    >
                        <AttachEmailOutlined />&nbsp;Datei anhängen
                        <input type="file" hidden onChange={e => setAttachment(e.target.files)} />
                    </Button>
                </Grid>
            </Grid>
            <Button
                variant="contained"
                fullWidth
                sx={{ mt: '1rem' }}
                disabled={new_comment.length === 0 || sending}
                onClick={postComment}
            >
                Kommentar abschicken
            </Button>
            {event.comments.map(comment => <EventCommentCard comment={comment} key={comment.pk} onDelete={deleteComment} />)}
        </>
    )
}