import { AppBar, CssBaseline, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography, styled, Container } from "@mui/material";
import CalendarIcon from '@mui/icons-material/CalendarToday'
import PersonIcon from '@mui/icons-material/Person'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Box } from "@mui/system";
import { Link, Outlet } from 'react-router-dom'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import deLocale from 'date-fns/locale/de';
import { Home, Person, Menu, ChevronLeft } from "@mui/icons-material";
import store from './store'
import { fetchWhoAmI } from "./slices/usersSlice";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { LoginView } from "./components/LoginView";



const theme = createTheme()

const drawerWidth = 250

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));


function App() {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const whoami = useSelector(state => state.users.whoami)

  useEffect(() => {
    store.dispatch(fetchWhoAmI)
  }, [])

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen)
  }

  if (!(whoami && whoami.pk)) {
    return (
      <ThemeProvider theme={theme}>
        <LoginView />
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
        <CssBaseline />
        <AppBar
          position="sticky"
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
            >
              <Menu />
            </IconButton>
            <Typography variant="h6" component="h1" sx={{ flexGrow: 1 }}>
              Event Planner
            </Typography>
            { whoami &&
              <>
              <Person />
              <Typography variant="body1" component="h4">
                {whoami.first_name} {whoami.last_name}
              </Typography>
              </>
          }
          </Toolbar>
        </AppBar>
        <Drawer
          variant="temporary"
          open={drawerOpen}
          onClose={toggleDrawer}
        >
          <DrawerHeader>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeft />
            </IconButton>
          </DrawerHeader>
          <Box sx={{width: drawerWidth}}>
          <List>
            <ListItem button component={Link} to="/" onClick={toggleDrawer}>
              <ListItemIcon>
                <Home />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button component={Link} to="/events" onClick={toggleDrawer}>
                <ListItemIcon>
                  <CalendarIcon />
                </ListItemIcon>
                <ListItemText>
                  Events
                </ListItemText>
            </ListItem>
            <ListItem button component={Link} to="/users" onClick={toggleDrawer}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText>
                Personen
              </ListItemText>
            </ListItem>
          </List>
          </Box>
        </Drawer> 
          <Container maxWidth="md" sx={{ mt: '2rem', mb: '4rem' }}>
            <Outlet />
          </Container>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
