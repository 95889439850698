import { createSlice } from "@reduxjs/toolkit"
import api from '../api'

export const eventsSlice = createSlice({
    name: 'events',
    initialState: {
        events: [],
    },
    reducers: {
        setEvents: (state, action) => {
            state.events = action.payload
        },
    }
})

export async function fetchEvents(dispatch, getState) {
    api.fetchEvents()
    .then(events => {
        dispatch({ type: 'events/setEvents', payload: events })
    })
}

export default eventsSlice.reducer
