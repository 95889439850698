import { Person, Warning, Assignment, Edit, Delete } from "@mui/icons-material"
import { IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material"
import PropTypes from 'prop-types'
import { useSelector } from "react-redux"
import api from "../api"

export const RoleAssignmentListItem = (props) => {
    const roles = useSelector((state) => state.roles.roles)
    const users = useSelector((state) => state.users.users)

    const role = roles.find(role => role.url === props.assignment.role)
    const user = users.find(user => user.url === props.assignment.user)

    const user_name = user ? (
        <span>
            {user.first_name + " " + user.last_name}
            {!props.assignment.confirmed ?
                <Typography variant="caption" sx={{ color: "warning.main" }}>
                    &nbsp;angefragt
                </Typography> :
                <Typography variant="caption" sx={{ color: "success.main" }}>
                    &nbsp;bestätigt
                </Typography>
            }
        </span>
     ) : "Nicht zugeordnet"
    const role_name = role.name

    const editAssignment = () => {
        props.editAssignment(props.assignment)
    }

    const removeAssignment = () => {
        if (window.confirm("Soll die Zuordnung für " + role_name + " wirklich entfernt werden?")) {
            api.deleteRoleAssignment(props.assignment)
            .then(() => {
                props.fetchEvent()
            })
        }
    }

    let action_button
    if (user) {
        action_button = <IconButton color="primary" onClick={editAssignment}><Edit /></IconButton>
    } else {
        action_button = <IconButton color="primary" onClick={editAssignment}><Assignment /></IconButton>
    }

    return (
        <>
            <ListItem>
                <ListItemIcon>
                    {user ? <Person /> : <Warning />}
                </ListItemIcon>
                <ListItemText primary={user_name} secondary={role_name} primaryTypographyProps={user ? {} : {color: "error"}} />
                <ListItemSecondaryAction>
                    {action_button}
                    <IconButton color="error" onClick={removeAssignment}>
                        <Delete />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem> 
        </>
    )
}

RoleAssignmentListItem.propTypes = {
    roles: PropTypes.array,
    assignment: PropTypes.object,
    editAssignment: PropTypes.func
}