import { List, ListItem, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { fetchEvents } from '../slices/eventsSlice'
import { fetchRoles } from '../slices/rolesSlice'
import { fetchWhoAmI } from '../slices/usersSlice'
import store from '../store'
import EventListItem from './EventListItem'

export const HomeView = () => {
    const events = useSelector(state => state.events.events)
    const whoami = useSelector(state => state.users.whoami)

    useEffect(() => {
        store.dispatch(fetchEvents)
        store.dispatch(fetchWhoAmI)
        store.dispatch(fetchRoles)
    }, [])

    let requests
    if (events.length > 0) {
        requests = whoami.roleassignments.filter(assignment => !assignment.confirmed)
    } else {
        requests = []
    }

    const findEvent = (event_url) => {
        return events.find(e => e.url === event_url)
    }

    let upcoming_events = []
    if (events.length > 0) {
        upcoming_events = whoami.roleassignments.filter(assignment => assignment.confirmed && new Date(findEvent(assignment.event).date) > new Date())
    }

    return (
        <>
            <Typography variant="h4">
                Anfragen
            </Typography>

            <List>
                {requests.map(request => <EventListItem key={request.pk} request={request} event={events.find(e => e.url === request.event)} />)}
                {
                    requests.length === 0 &&
                    <ListItem>
                        Keine Anfragen
                    </ListItem>
                }
            </List>

            <Typography variant="h4">
                Anstehende Aufgaben
            </Typography>

            <List>
                {upcoming_events.map(assignment => <EventListItem key={assignment.pk} event={findEvent(assignment.event)} />)}
                {
                    upcoming_events.length === 0 &&
                    <ListItem>
                        Keine anstehenden Aufgaben
                    </ListItem>
                }
            </List>
        </>
    )
}
