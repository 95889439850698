import { List } from "@mui/material"
import PropTypes from "prop-types"
import { useState } from "react"
import api from "../api"
import RoleAssignmentEditDialog from "./RoleAssignmentEditDialog"
import { RoleAssignmentListItem } from "./RoleAssignmentListItem"

export const RoleAssignmentList = (props) => {
    const [assignment, setAssignment] = useState(undefined)
    const [dialog_open, setDialogOpen] = useState(false)

    const editAssignment = item => {
        setAssignment(item)
        setDialogOpen(true)
    }

    const selectUser = (user) => {
        setDialogOpen(false)
        const roleAssignmentUpdate = {
            url: assignment.url,
            user: user.url,
        }
        if (user !== assignment.user) {
            roleAssignmentUpdate.confirmed = null
        }
        api.updateRoleAssignment(roleAssignmentUpdate)
        .then(() => {
            props.fetchEvent()
        })
    }

    const handleDialogClose = () => setDialogOpen(false)

    return (
        <>
            <List>
                {props.assignments.map(assignment => <RoleAssignmentListItem fetchEvent={props.fetchEvent} editAssignment={editAssignment} assignment={assignment} key={assignment.pk} roles={props.roles} />)}
            </List>
            <RoleAssignmentEditDialog open={dialog_open} assignment={assignment} onSelect={selectUser} onClose={handleDialogClose} />
        </>
    )
}

RoleAssignmentList.propTypes = {
    roles: PropTypes.array,
    assignments: PropTypes.array,
}
