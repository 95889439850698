import { createSlice } from "@reduxjs/toolkit"
import api from '../api'

export const rolesSlice = createSlice({
    name: 'roles',
    initialState: {
        roles: []
    },
    reducers: {
        setRoles: (state, action) => {
            state.roles = action.payload
        },
    }
})

export async function fetchRoles(dispatch, getState) {
    api.fetchRoles()
    .then(roles => {
        dispatch({ type: 'roles/setRoles', payload: roles })
    })
}

export const { setRoles } = rolesSlice.actions
export default rolesSlice.reducer
