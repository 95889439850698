import { AttachFile, Delete } from "@mui/icons-material"
import { Avatar, Button, Card, CardActions, CardContent, CardHeader, IconButton } from "@mui/material"
import fileSize from "filesize"
import { useSelector } from "react-redux"
import { readableDate } from '../util'

export const EventCommentCard = ({ comment, onDelete }) => {
    const users = useSelector(s => s.users.users)
    const whoami = useSelector(s => s.users.whoami)
    const user = users.find(u => u.url === comment.created_by)

    if (!user) {
        return <></>
    }

    const userAvatarString = user.first_name.charAt(0) + user.last_name.charAt(0)    
    const full_name = user.first_name + " " + user.last_name

    return (
        <Card sx={{ mt: '1rem' }}>
            <CardHeader
                avatar={
                    <Avatar>
                        {userAvatarString}
                    </Avatar>
                }
                title={full_name}
                subheader={readableDate(comment.created_at)}
            />
            <CardContent variant="body2" color="text.secondary" className="display-linebreak">
                {comment.content}
            </CardContent>
            <CardActions>
                { comment.attachment &&
                <Button href={comment.attachment} download>
                    <AttachFile /> {comment.attachment_filename} herunterladen ({fileSize(comment.attachment_filesize)})
                </Button>
                }
                {
                    comment.created_by === whoami.url &&
                    <IconButton onClick={() => onDelete(comment)} color="error" sx={{ ml: "auto" }}>
                        <Delete />
                    </IconButton>
                }
            </CardActions>
        </Card>
    )
}
