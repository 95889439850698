import { IconButton, ListItem, ListItemIcon, ListItemButton, ListItemText, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import CalendarIcon from '@mui/icons-material/CalendarToday'
import { readableDate } from '../util'
import { Cancel, Check } from "@mui/icons-material"
import { useSelector } from "react-redux"
import api from "../api"
import store from "../store"
import { fetchWhoAmI } from "../slices/usersSlice"

export default function EventListItem({ event, request }) {
    const roles = useSelector(state => state.roles.roles)

    let secondary = readableDate(event.date)
    let listItemSecondaryAction = null

    const confirm = (event) => {
        api.updateRoleAssignment({
            url: request.url,
            confirmed: new Date()
        })
        .then(() => {
            store.dispatch(fetchWhoAmI)
        })
        event.stopPropagation()
    }

    const deny = (event) => {
        api.updateRoleAssignment({
            url: request.url,
            user: null
        })
        .then(() => {
            store.dispatch(fetchWhoAmI)
        })
        event.stopPropagation()
    }

    if (request) {
        const role = roles.find(r => r.url === request.role)
        listItemSecondaryAction = (
            <>
                <IconButton onClick={deny} color="error">
                    <Cancel />
                </IconButton>
                <IconButton onClick={confirm} color="success">
                    <Check />
                </IconButton>
            </>
        )
        if (role) {
            secondary = (
                <>
                    Angefragt für <Typography component="span" variant="body2" color="text.primary" fontWeight={'bold'}>{role.name}</Typography> - {secondary}
                </>
            )
        }
    }

    return (
        <ListItem secondaryAction={listItemSecondaryAction} >
            <ListItemButton component={Link} to={`/events/${event.pk}`}>
                <ListItemIcon>
                    <CalendarIcon />
                </ListItemIcon>
                <ListItemText primary={event.name} secondary={secondary} />
            </ListItemButton>
        </ListItem>
    )
}
