import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import EventList from './routes/EventList';
import UserList from './routes/UserList';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import EventDetail from './routes/EventDetail';
import store from './store'
import { Provider } from 'react-redux'
import { HomeView } from './components/HomeView';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<HomeView />} />
            <Route path="events" element={<EventList />} />
            <Route path="events/new" element={<EventDetail new />} />
            <Route path="events/:eventId" element={<EventDetail />} />

            <Route path="users" element={<UserList />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
