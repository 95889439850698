import { Autocomplete, Button, Grid, TextField, Typography, Box } from "@mui/material"
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { useNavigate } from "react-router-dom";
import { fetchRoles } from "../slices/rolesSlice";
import { fetchUsers } from "../slices/usersSlice";
import store from '../store'

import api from '../api'

import { RoleAssignmentList } from "../components/RoleAssignmentList";
import { useSelector } from "react-redux";
import { EventEditForm } from "../components/EventEditForm";
import { EventComments } from "../components/EventComments";
import { DeleteForever, Person } from "@mui/icons-material";


const EventDetail = (props) => {
    const [event, setEvent] = useState(undefined)
    const roles = useSelector((state) => state.roles.roles)
    const users = useSelector((state) => state.users.users)
    const whoami = useSelector(state => state.users.whoami)
    const navigate = useNavigate()

    let created_by = null
    if (event) {
        created_by = users.find(u => u.url === event.created_by)
    }

    const [selectedRole, setSelectedRole] = useState(null)

    let params = useParams()
    let eventId = parseInt(params.eventId)

    useEffect(() => {
        if (!eventId) {
            return
        }
        api.fetchEvent(eventId)
        .then(event => {
            setEvent(event)
        })

        store.dispatch(fetchRoles)
        store.dispatch(fetchUsers)
    }, [eventId])


    const fetchEvent = () => {
        api.fetchEvent(eventId)
        .then(event => {
            setEvent(event)
        })
    }

    const addRoleAssignment = (role) => {
        api.createRoleAssignment({
            event: event.url,
            role: role.url,
        })
        .then(() => {
            fetchEvent()
        })
    }

    const deleteEvent = () => {
        if (window.confirm("Möchten Sie das Ereignis " + event.name + " wirklich löschen? Dies kann nicht rückgängig gemacht werden!"))
        {
            api.deleteEvent(event)
            .then(
                setTimeout(() => {
                    navigate("/events")
                }, 200)
            )
        }
    }

    if (!event && !props.new) {
        return (
            <>
                <Typography variant="h4" sx={{ mb: "1rem" }}>
                    Loading Event
                </Typography>
            </>
        )
    }

    return (
        <>
            {
                created_by &&
                <Box sx={{ mb: '.5rem' }}>
                    <Person sx={{ position: 'relative', top: 6 }} />
                    <Typography variant="body1" component="span">
                        <strong>{created_by.first_name} {created_by.last_name}</strong> plant
                    </Typography>
                </Box>
            }
            <Typography variant="h4" sx={{ mb: "1rem" }}>
                {props.new ? "Neues Event" : event.name}
            </Typography>
            <EventEditForm event={event} new={props.new} onSaved={e => setEvent(e)} />
            {
                !props.new &&
                <>
                    <Typography variant="h5" sx={{ mb: "1rem", mt: "1rem" }}>
                        Mitarbeiter
                    </Typography>
                    <RoleAssignmentList assignments={event.roleassignments} roles={roles} fetchEvent={fetchEvent} />
                    <Typography variant="h6" sx={{ mb: "1rem", mt: "1rem" }}>
                        Rolle hinzufügen
                    </Typography>
                    <Grid container>
                        <Autocomplete
                            options={roles}
                            getOptionLabel={r => r.name}
                            renderInput={(params) => <TextField {...params} label="Rolle" />}
                            sx={{ flexGrow: 1, mr: "1rem" }}
                            onChange={(event, value) => setSelectedRole(value)}
                        />
                        <Button onClick={() => {addRoleAssignment(selectedRole)}}>
                            Hinzufügen
                        </Button>
                    </Grid>
                    <EventComments event={event} onNewComment={fetchEvent} />
                </>
            }
            { event && whoami.url === event.created_by &&
                <Button sx={{ mt: '1em' }} fullWidth variant="contained" color="error" onClick={deleteEvent}>
                    <DeleteForever />&nbsp;Event löschen
                </Button>
            }
        </>
    )
}

export default EventDetail
