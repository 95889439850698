import { LoginOutlined } from '@mui/icons-material'
import { AppBar, Grid, Toolbar, Typography, TextField, Card, CardContent, CardActions, Button } from '@mui/material'

import React, { useState } from 'react'
import api from '../api'
import { fetchWhoAmI } from '../slices/usersSlice'
import store from '../store'

export const LoginView = () => {
  const [user, setUser] = useState("")
  const [password, setPassword] = useState("")

  const login = () => {
    api.login(user, password)
    .then(() => {
        store.dispatch(fetchWhoAmI)
      }
    )
  }

  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Event Planner
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={3}>
          <Card variant="outlined">
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Login
              </Typography>
              <TextField
                fullWidth
                label="Benutzername"
                value={user}
                onChange={e => setUser(e.target.value)}
              />
              <TextField
                fullWidth
                label="Passwort"
                value={password}
                onChange={e => setPassword(e.target.value)}
                onKeyPress={e => {if (e.key === 'Enter') {login()}}}
                type="password"
                sx={{mt: '1rem'}}
              />
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end' }}>
              <Button onClick={login} startIcon={<LoginOutlined />}>
                Login
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}
