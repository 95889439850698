import { useState, useEffect } from "react"
import { Typography, List, Input, InputAdornment, Button, Grid } from "@mui/material"
import api from '../api'
import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/Add'
import { Link } from 'react-router-dom'
import EventListItem from "../components/EventListItem"

export default function EventList() {
    const [events, setEvents] = useState([])
    const [searchTerm, setSearchTerm] = useState("")

    const handleSearchTermChange = (e) => { setSearchTerm(e.target.value) }

    useEffect(() => {
        api.fetchEvents()
        .then((fetchedEvents) => {
            setEvents(fetchedEvents)
        })
    }, [])

    const filtered_events = events.filter(event => event.name.toLowerCase().includes(searchTerm.toLowerCase()))

    const upcoming_events = filtered_events.filter(event => new Date(event.date) >= new Date())
    const past_events = filtered_events.filter(event => new Date(event.date) < new Date())

    return (
        <>
            <Typography variant="h4" sx={{ mb: "1rem" }}>
                Events
            </Typography>
            <Grid container>
                <Input
                    placeholder="Suchen"
                    startAdornment={
                        <InputAdornment position="start">
                        <SearchIcon />
                        </InputAdornment>
                    }
                    onChange={handleSearchTermChange}
                    sx={{ flexGrow: 1, mr: '1rem' }}
                />
                <Button component={Link} to={'/events/new'}>
                    <AddIcon />
                    Neues Event
                </Button>
            </Grid>
            <List>
                {upcoming_events.map(event => <EventListItem event={event} key={event.pk} />)}
            </List>
            <Typography variant="h5" sx={{ mb: "1rem "}}>
                Vergangene Events
            </Typography>
            <List>
                {past_events.map(event => <EventListItem event={event} key={event.pk} />)}
            </List>
        </>
    )
}
