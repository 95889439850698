import { IconButton, ListItem, ListItemIcon, ListItemText } from "@mui/material"

import PersonIcon from "@mui/icons-material/Person"
import { ArrowRight } from "@mui/icons-material"

const full_name = user => user.first_name + " " + user.last_name

export default function UserListItem({ user, onSelect }) {
    const selectUser = () => {
        onSelect(user)
    }

    let listItemSecondaryAction
    if (onSelect) {
        listItemSecondaryAction = (
            <IconButton onClick={selectUser}>
                <ArrowRight />
            </IconButton>
        )
    } else {
        listItemSecondaryAction = null
    }

    return (
        <ListItem secondaryAction={listItemSecondaryAction}>
            <ListItemIcon>
                <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={full_name(user)} />
        </ListItem>
    )
}
