import { useEffect } from "react"
import { List } from "@mui/material"
import UserListItem from '../components/UserLIstItem'
import store from '../store'
import { fetchUsers } from "../slices/usersSlice"
import { useSelector } from "react-redux"

export default function UserList(props) {
    const users = useSelector((state) => state.users.users)

    useEffect(() => {
        store.dispatch(fetchUsers)
    }, [])

    return (
        <>
            <List>
                {users.map(user => <UserListItem user={user} key={user.pk} onSelect={props.onSelect} />)}
            </List>
        </>
    )
}
