import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'


let apiBaseUrl
if (process.env.NODE_ENV === "development") {
    apiBaseUrl = 'http://localhost:8000/'
} else {
    apiBaseUrl = 'https://api.reineke.dev/'
}

const http = axios.create({
    baseURL: apiBaseUrl,
})

const plain_http = axios.create({
    baseURL: apiBaseUrl,
})

http.interceptors.request.use(config => {
    if (localStorage.getItem('eptoken')) {
        config.headers['X-Access-Token'] = "Bearer " + localStorage.getItem('eptoken')
    }
    return config
})

const refreshAuthToken = failedRequest => {
    return http.post("api/token/refresh/", {
        refresh: localStorage.getItem('eprefreshtoken')
    }, {
        skipAuthRefresh: true
    })
    .then(response => {
        localStorage.setItem('eptoken', response.data.access)
        failedRequest.response.config.headers['Authorization'] = 'Bearer ' + response.data.access
        return Promise.resolve()
    })
}

createAuthRefreshInterceptor(http, refreshAuthToken)

const api = {
    fetchEvents() {
        return http.get("event/")
        .then(res => res.data)
    },
    fetchEvent(eventId) {
        return http.get("event/" + eventId)
        .then(res => res.data)
    },
    createEvent(event) {
        return http.post("event/", event)
        .then(res => res.data)
    },
    updateEvent(event) {
        return http.patch(event.url, event)
        .then(res => res.data)
    },
    deleteEvent(event) {
        return http.delete(event.url)
        .then(res => res.data)
    },
    createEventComment(comment) {
        var formData = new FormData()
        for (const [key, value] of Object.entries(comment)) {
            formData.append(key, value)
        }
        return http.post("eventcomment/", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(res => res.data)
    },
    deleteEventComment(comment) {
        return http.delete(comment.url)
        .then(res => res.data)
    },
    fetchUsers() {
        return http.get("user/")
        .then(res => res.data)
    },
    fetchWhoAmI() {
        window.axiosPromise = http.get("user/whoami")
        .then(res => {
            return res.data
        })
        return window.axiosPromise
    },
    fetchRoles() {
        return http.get("role/")
        .then(res => res.data)
    },
    createRoleAssignment(assignment) {
        return http.post("roleassignment/", assignment)
        .then(res => res.data)
    },
    updateRoleAssignment(assignment) {
        return http.patch(assignment.url, assignment)
        .then(res => res.data)
    },
    deleteRoleAssignment(assignment) {
        return http.delete(assignment.url)
        .then(res => res.data)
    },
    login(user, password) {
        console.log("logging in " + user)
        return plain_http.post("api/token/", {username: user, password: password})
        .then(res => {
            console.log("Setting login token to " + res.data.access)
            localStorage.setItem('eptoken', res.data.access)
            localStorage.setItem('eprefreshtoken', res.data.refresh)
        })
    }
}

export default api