import { DateTimePicker } from '@mui/lab'
import { Button, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import api from '../api'

export const EventEditForm = (props) => {
    const whoami = useSelector(s => s.users.whoami)
    const [eventName, setEventName] = useState("")
    const [eventDate, setEventDate] = useState(new Date())
    const [eventDescription, setEventDescription] = useState("")
    const [eventLocation, setEventLocation] = useState("")
    const navigate = useNavigate()

    useEffect(() => {
        if (!props.event) {
            return
        }
        setEventName(props.event.name)
        setEventDate(new Date(props.event.date))
        setEventDescription(props.event.description)
        setEventLocation(props.event.location)
    }, [props.event])

    const noChanges = () => {
        if (!(props.event  && eventDate)) {
            if (props.new) {
                return eventName.length === 0
            }
            return true
        }
        return (
            eventName === props.event.name &&
            eventDescription === props.event.description &&
            eventLocation === props.event.location &&
            eventDate.getTime() === new Date(props.event.date).getTime()
        )
    }

    const saveEvent = () => {
        if (props.new) {
            api.createEvent({
                name: eventName,
                description: eventDescription,
                location: eventLocation,
                date: eventDate,
                created_by: whoami.url,
            })
            .then(event => {
                navigate("/events")
            })
        } else {
            api.updateEvent({
                url: props.event.url,
                name: eventName,
                description: eventDescription,
                location: eventLocation,
                date: eventDate
            })
            .then(event => {
                props.onSaved(event)
            })
        }
    }

    return (
        <>
            <TextField
                fullWidth
                label="Name"
                variant="outlined"
                value={eventName}
                onChange={e => setEventName(e.target.value)}
                required
                margin="dense"
            />
            <TextField
                fullWidth
                label="Beschreibung"
                variant="outlined"
                value={eventDescription}
                onChange={e => setEventDescription(e.target.value)}
                multiline
                margin="dense"
            />
            <TextField
                fullWidth
                label="Ort"
                variant="outlined"
                value={eventLocation}
                onChange={e => setEventLocation(e.target.value)}
                margin="dense"
            />
            <DateTimePicker
                label="Datum"
                renderInput={(props) => <TextField margin="dense" {...props} />}
                value={eventDate}
                onChange={newDate => setEventDate(newDate)}
            />
            <Button
                variant="contained"
                fullWidth
                sx={{ mt: '1rem' }}
                disabled={noChanges()}
                onClick={saveEvent}
            >
                Speichern
            </Button>
        </>
    )
}
